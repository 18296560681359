<template>
  <div class="Category">
    <v-card class="mx-auto" max-width="1170">
      <v-toolbar color="orange" dark>
        <v-toolbar-title>製造メーカー一覧</v-toolbar-title>
      </v-toolbar>
      <p>調べたい製品の製造メーカーを選択してください。一部の商品は、製造メーカーではなく販売者(リセラー)で登録されている場合もあります。</p>
      <v-list>
        <v-list-item-group>
          <v-list-item :to="{ name: 'Top' }">
            <v-list-item-content>トップに戻る</v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'Category' }">
            <v-list-item-content>カテゴリ一覧に戻る</v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
    <v-card class="mx-auto" max-width="1170">
      <v-toolbar color="orange" dark>
        <v-toolbar-title>製造メーカー一覧</v-toolbar-title>
      </v-toolbar>
      <v-list two-line>
        <v-list-item-group>
          <template v-for="manufacturer in manufacturers">
            <v-list-item :key="manufacturer.id" :to="{ name: 'Brand', params: { categorySlug: $route.params.categorySlug, manufacturerSlug: manufacturer.slug } }">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-text="manufacturer.name"></v-list-item-title>
                  <v-list-item-subtitle class="text--secondary" v-text="manufacturer.productCount"></v-list-item-subtitle>
                  <v-list-item-subtitle v-text="manufacturer.updated"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>


<script>
const axios = require('axios')

export default{
  name: 'Manufacturer',
  components: {
  },
  data () {
    return {
      manufacturers: null
    }
  },
  mounted() {
    const categorySlug = this.$route.params.categorySlug
    axios.get('https://api.pc-combo.tokyo/categories/' + categorySlug + '.json')
        .then(response => (this.manufacturers = response.data.manufacturers))
        .catch(error => console.log(error))
  }
}
</script>
